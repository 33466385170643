export const AIRTABLE_API_KEY = "key2OCU6SyWP4OFMJ";
export const AIRTABLE_API = "https://api.airtable.com/v0/app9uUySA3zLRrzTX";
export const CANDIDATES_ENDPOINT = "https://api.airtable.com/v0/app9uUySA3zLRrzTX/Candidates?api_key=key2OCU6SyWP4OFMJ"
export const VOTERS_ENDPOINT = "https://api.airtable.com/v0/app9uUySA3zLRrzTX/Voters?api_key=key2OCU6SyWP4OFMJ"
export const FOUNDERS_VOTE_ENDPOINT = "https://api.airtable.com/v0/app9uUySA3zLRrzTX/Voting%2FFounders?api_key=key2OCU6SyWP4OFMJ"
export const HOLDERS_VOTE_ENDPOINT = "https://api.airtable.com/v0/app9uUySA3zLRrzTX/Voting%2FHolders?api_key=key2OCU6SyWP4OFMJ"


export const NAVBAR_COLOR = "#eee"

