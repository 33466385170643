import React, { Component } from 'react'
import { Box, Text, TextInput, Button, Meter, Stack } from 'grommet'
import { FingerPrint, Checkmark, Info } from 'grommet-icons'
import CandidateCard from '../../molecules/candidateCard'
import { submitVotes } from '../../../helpers'

class Ballot extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    const { 
      candidates,
      cardsBackground, 
      numberOfVotes,
      hasVoted,
      submitMessage,
    } = this.props;
    const usedVotes = this.calcUsedVotes() || 0;
    const unusedVotes = numberOfVotes - usedVotes;
    const enableVoting = !hasVoted && !!numberOfVotes 

    const votes = [];

    for(let candidate of candidates) {
      const candidateId = candidate.id;
      const howManyVotes = this.state[candidateId];

      if (howManyVotes) {
        votes.push({ candidateId, howManyVotes })
      }
    }
    const voteIsSpread = numberOfVotes < 3 || votes.length >= 3;
    const enableSubmission = voteIsSpread && unusedVotes === 0;

    if(!candidates) {
      return null;
    }

    return (
      <Box>
        {
          hasVoted && 
          <Box 
            background="#f1ffe2" 
            pad="10px" 
            margin={{bottom: "large"}}
            animation="fadeIn"
          >
            <Text alignSelf="center" size="16px">
              { submitMessage || "Thank you for submitting your vote!" }
            </Text>
          </Box>
        }
        {
          (enableVoting && !hasVoted) &&
          // Instructions 
          <Box margin={{bottom: "large"}}>
            <Box background="rgba(255, 231, 198, 0.3)" pad="10px"> 
              {
                numberOfVotes >= 3 &&
                <Box>
                  <Text size="small" style={{lineHeight: "25px"}}>
                    - Please distribute your votes across at least 3 nominees.

                    {
                      votes.length >= 3 && 
                      <Text margin={{left: "small"}}>
                      <Checkmark color="#8cc74f" size="20px" fontWeight="bold" />
                    </Text>
                    }
                  </Text>
                </Box>
              }
    
              <Box>
                <Text size="small" style={{lineHeight: "25px"}}>
                  - You must allocate all your votes before you can submit your ballot.

                  {
                    unusedVotes === 0  && 
                    <Text margin={{left: "small"}}>
                      <Checkmark 
                        color="#8cc74f" 
                        size="20px" 
                        fontWeight="bold" 
                        height="0" 
                      />
                    </Text>
                  }
                </Text>
              </Box>

              <Box  margin={{top: "small"}}>
                <Text size="xsmall" style={{fontStyle: "italic", fontSize: "11px"}}>
                  <Text margin={{right: "3px"}}>
                    <Info color="#000" size="10px" />
                  </Text>
                  Click on a card to expand and read more about the candidate.
                </Text>
              </Box>
            </Box>
          </Box>
        }
  
        <Text weight="bold">
          Candidates

          <Text style={{float: "right"}}>
            {
              enableVoting  &&
              <Stack>
                 <Meter
                  type="bar"
                  size="small"
                  background="light-2"
                  values={[{ 
                    value: (usedVotes/numberOfVotes) * 100,
                    color: "#f99d1c"
                  }]}
                />
                <Box pad="2px 5px" align="end">
                  <Text weight="normal" size="small">
                    {
                      enableSubmission ? 'All done!' : `${unusedVotes} vote${unusedVotes !== 1 ?'s':''} ${usedVotes?'left':''}`
                    } 
                  </Text>
                </Box>
              </Stack>
             
            }
          </Text>
        </Text>
        {
          candidates && candidates.map((candidate, index) => (
            <Box key={candidate.id} margin={{ top: "medium" }}>
              <Box fill direction="row" justify="between" >
                
                <CandidateCard
                  candidate={candidate.fields} 
                  cardsBackground={cardsBackground} 
                  style={{ width: "100%" }}
                />

                {
                  enableVoting &&
                  <Box margin={{left: "medium"}} style={{
                    width: "60px",
                    // padding: "5px"
                  }}>
                    <TextInput 
                      // type="number"
                      value={this.state[candidate.id] || ""} 
                      onChange={(event)=> {
                        this.vote(candidate, event.target.value)
                      }}
                      min={0}
                      max={unusedVotes}
                      size="small"
                      width="100%"          
                      style={{
                        fontWeight: "normal",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    
                    />
                  </Box>
                }
                
              </Box>
            </Box>
          ))
        }
        
        {
          enableVoting &&
          <Box margin={{top: "large"}} alignSelf="center">
            <Button 
              type="submit"
              icon={<FingerPrint />}
              label="vote"
              reverse
              onClick={() => this.submit(votes)}
              color={enableSubmission ? "#f99d1c":"#888"}
              disabled={!enableSubmission}
            />
          </Box>
        } 
         
        {
          enableVoting && 
          <Box  margin={{top: "medium"}} >
            <Box background="rgba(255, 231, 198, 0.3)" pad="10px"> 
              {
                numberOfVotes >= 3 &&
                <Box>
                  <Text size="small">
                    - Please distribute your votes across at least 3 nominees.
                    {
                      votes.length >= 3 && 
                      <Text margin={{left: "small"}}>
                      <Checkmark color="#8cc74f" size="20px" fontWeight="bold" />
                    </Text>
                    }
                    
                  </Text>
                </Box>
              }
    
              <Box margin= {{top: "small"}}>
                <Text size="small">
                  - You must allocate all your votes before you can submit your ballot.
                  {
                    unusedVotes === 0  && 
                    <Text margin={{left: "small"}}>
                      <Checkmark color="#8cc74f" size="20px" fontWeight="bold" />
                    </Text>
                  }
                </Text>
              </Box>
            </Box>
          </Box>
        }
        
      </Box>
    );
  }

  vote = (candidate, howManyVotes) => {
    if(!howManyVotes || howManyVotes < 1) {
      return this.setState({
        [candidate.id]: null,
      })
    }
    
    const candidateId = candidate.id;
    const { numberOfVotes } = this.props;

    const castedVotes = Number(howManyVotes);
    const totalUsedVotes = this.calcUsedVotes({[candidateId]: castedVotes});

    this.setState({
      [candidateId]: (totalUsedVotes <= numberOfVotes) ? castedVotes : this.state[candidateId],
    })
  }

  calcUsedVotes = (newVote={}) => {
    let totalUsedvotes = 0;
    const votes = {...this.state, ...newVote}
    this.props.candidates.forEach(candidate => {
      const candidateId = [candidate.id];
      const votesForCandidate = newVote[candidateId] || votes[candidateId]
      if (typeof votesForCandidate === 'number') {
        totalUsedvotes += votesForCandidate || 0
      }
    })
    return totalUsedvotes
  }

  submit = (votes) => {
    submitVotes(votes, this.props.electionName);
  }
}

export default Ballot;