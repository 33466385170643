import React, { Component } from 'react'
import { Box, Text } from "grommet";
import Anchor from '../../atoms/anchor'
import Page from '../../templates/page';
import { getVoter, checkVoterEligibility } from '../../../helpers'

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voter: null,
      eligibleForInitiatorsVote: false,
      eligibleForFoundersVote: false,
      eligibleForHoldersVote: false,
    }

    this.getData()
  }

  render() {
    // const {
    //   eligibleForInitiatorsVote,
    //   eligibleForFoundersVote,
    //   eligibleForHoldersVote,
    // } = this.state;

    return (
      <Page>
        <Box margin={{bottom: "30px"}}>
          <Text>Landing</Text>

          <Box>
            {/* { */}
              {/* eligibleForInitiatorsVote && */}
              <Anchor href={`/initiators`}>
                <Text>Initiators Vote</Text>
              </Anchor>
            {/* } */}
            {/* { */}
              {/* eligibleForFoundersVote && */}
              <Anchor href="/founders">
                <Text>Founders Vote</Text>
              </Anchor>
            {/* } */}
            {/* { */}
              {/* // eligibleForHoldersVote && */}
              <Anchor href="/holders">
                <Text>Holders Vote</Text>
              </Anchor>
            {/* } */}
          </Box>
        </Box>
      </Page>
    )
  }

  getData() {
    getVoter()
      .then(voter => {
        if (voter) {
          const { initiators, founders, holders } = checkVoterEligibility(voter);

          this.setState({
            voter,
            eligibleForInitiatorsVote: initiators,
            eligibleForFoundersVote: founders,
            eligibleForHoldersVote: holders
          })
        }
      })
  }
}

export default Landing;

