import React, { Component } from 'react'
import { Box } from "grommet";

class Card extends Component {
  render() {
    const {
      round, 
      background, 
      header, 
      children, 
    } = this.props;

    return (
      <Box
        {...this.props}
        background={{ color: background || "transparent", opacity: "strong" }}
        round={round || "xsmall"}
      >
        {header}
        {children}
      </Box>
    );
  }
}

export default Card;