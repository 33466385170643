import React, { Component } from 'react'
import { Box, Text } from 'grommet';
import CandidateCard from '../../molecules/candidateCard'

class CandidateList extends Component {
  render() {
    const { candidates, title, cardsBackground } = this.props;

    if(!candidates) {
      return null;
    }

    return (
      <Box>
        <Text weight="bold">{title}</Text>
        {
          candidates.map(candidate => (
            <Box key={candidate.id} margin={{ top: "medium" }}>
              <CandidateCard
                candidate={candidate.fields} 
                cardsBackground={cardsBackground} 
              />
            </Box>
          ))
        }
      </Box>
    );
  }
}

export default CandidateList;