import React, { Component } from 'react'
import { Box, Text } from "grommet";
import Page from '../../templates/page';
import CandidateList from '../../organisms/candidateList'
import Ballot from '../../organisms/ballot';
import { 
  getCandidates, 
  filterVerifiedCandidates, 
  filterElectedByInitiators,
  shuffle,
  getVoter,
  checkVoterEligibility
} from '../../../helpers'

class Founders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      candidates: null,
      voter: null,
      numberOfVotes: null,
      eligibleForFoundersVote: false,
      electedByInitiators: null,
      hasVoted: null,
    }

    this.getData()
  }

  render() {
    const { 
      candidates, 
      electedByInitiators, 
      numberOfVotes,
      hasVoted,
    } = this.state;
    
    return (
      <Page>
        <Box margin={{bottom: "30px"}}>
          <Box>
            <Text alignSelf="center">Founders' Vote</Text>
          </Box>

          {
            candidates && 
            <Box margin={{top: "large"}}>
              <Ballot 
                candidates={candidates} 
                cardsBackground="#bdc1cc" 
                numberOfVotes={numberOfVotes}
                hasVoted={hasVoted}
                electionName="founders"
                submitMessage="Thank you for submitting your vote! You will be contacted at your ndau protonmail address when it is time to vote in the Holders' Election."
              />
            </Box>
          }

          {
            electedByInitiators && 
            <Box margin={{top: "large"}}>
              <CandidateList 
                title={`Elected Initiators (${electedByInitiators.length}/9)`}
                candidates={electedByInitiators}
                cardsBackground="#6e6e6e"
              />
            </Box>
          } 
        </Box>
      </Page>
    )
  }

  getData = () => {
    getCandidates()
      .then(candidates => {
        this.setState({
          candidates: shuffle(filterVerifiedCandidates(candidates)),
          electedByInitiators: filterElectedByInitiators(candidates)
        })
      })  
    
    getVoter()
      .then(voter => {
        if (voter) {
          const { founders } = checkVoterEligibility(voter);   

          this.setState({
            voter,
            numberOfVotes: voter.fields["Number of founder votes"],
            eligibleForFoundersVote: founders,
            hasVoted: voter.fields["Voted/Founders"],
          })
        }
        
      })
  }
}

export default Founders

