import axios from 'axios'
import qs from 'query-string'
import { 
  CANDIDATES_ENDPOINT,
  VOTERS_ENDPOINT,
  FOUNDERS_VOTE_ENDPOINT,
  HOLDERS_VOTE_ENDPOINT,
  AIRTABLE_API,
  AIRTABLE_API_KEY
} from './constants'

export const getCandidates = () => {
  return axios.get(CANDIDATES_ENDPOINT)
    .then(response => {
      return response.data.records;
    })
}

export const filterByField = (candidates, filter) => {
  const results = candidates.filter(candidate => {
    return candidate.fields[filter.field] === filter.value;
  })

  return results.length > 0 && results;
}

export const filterVerifiedCandidates = (candidates) => {
  return filterByField(candidates, {
    field: "Status", 
    value: "Verified"
  }) 
}

export const filterElectedByInitiators = (candidates) => {
  return filterByField(candidates, {
    field: "Status", 
    value: "Elected/Initiators"
  }) 
}

export const filterElectedByFounders = (candidates) => {
  return filterByField(candidates, {
    field: "Status", 
    value: "Elected/Founders"
  }) 
}

export const getVoters = () => {
  return axios.get(VOTERS_ENDPOINT)
    .then(response => {
      return response.data.records;
    })
}

export const getVoterID = () => {
  const query = qs.parse(window.location.search);
  const { voter } = query;
  const voterId = window.decodeURIComponent(voter);;
  return window.encodeURIComponent(voterId);
}

export const getVoter = () => {
  const voterId = getVoterID();

  const VOTER_ENDPOINT = `${AIRTABLE_API}/Voters/${voterId}?api_key=${AIRTABLE_API_KEY}`
  return axios.get(VOTER_ENDPOINT)
    .then(response => {
      return response.data;
    })
    .catch(err => {})
}

export const checkVoterEligibility = (voter) => {
  return {
    initiators: voter.fields["Eligible for Initiators"],
    founders: voter.fields["Eligible for Founders"],
    holders: voter.fields["Eligible for Holders"]
  }
}

export const makeURLQuery = (additionalQuery) => {
  const query = qs.parse(window.location.search);
  const newQuery = {
    voter: query.voter,
    ...additionalQuery
  }
  return `?${qs.stringify(newQuery)}`;
};

export const submitVotes = (votes, electionName) => {
  const voterId = getVoterID();
  const VOTER_ENDPOINT = `${AIRTABLE_API}/Voters/${voterId}?api_key=${AIRTABLE_API_KEY}`
  const voteStatusFields = {
    founders: "Voted/Founders",
    holders: "Voted/Holders",
  }
  const voteStatusField = voteStatusFields[electionName];
  axios.patch(VOTER_ENDPOINT, {
    fields: {
      [voteStatusField] : true
    }
  })
  .then(() => window.location.reload() )


  const votingEndpoints = {
    founders: FOUNDERS_VOTE_ENDPOINT,
    holders: HOLDERS_VOTE_ENDPOINT,
  }
  const votingEndpoint = votingEndpoints[electionName];
  for (const vote of votes) {
    axios.post(votingEndpoint, {
      fields: {
        "voter ID":  voterId,
        "Vote for": [ vote.candidateId ],
        "Number of votes": vote.howManyVotes
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(err => {})
  }
}

// Fisher–Yates Shuffle
export const shuffle = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
