import React, { Component } from 'react'
import { Box, Text } from "grommet";
import Page from '../../templates/page';
import CandidateList from '../../organisms/candidateList'
// import RankBallot from '../../organisms/rankBallot';
import { 
  getCandidates, 
  filterElectedByInitiators,
} from '../../../helpers'

class Initiators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      electedByInitiators: null,
    }

    this.getData()
  }

  render() {
    const { electedByInitiators } = this.state;
    return (
      <Page>
        <Box margin={{bottom: "30px"}}>
          <Text alignSelf="center">Initiators' Vote</Text>

          {
            electedByInitiators && 
            <Box pad="large">
              <CandidateList 
                title={`Elected Initiators (${electedByInitiators.length}/9)`}
                candidates={electedByInitiators}
              />
            </Box>
          } 
        </Box>
      </Page>
    )
  }

  getData = () => {
    getCandidates()
      .then(candidates => {
        this.setState({
          electedByInitiators: filterElectedByInitiators(candidates)
        })
      })  
  }
}

export default Initiators