import React from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom"
import Landing from './components/pages/landing'
import Initiators from './components/pages/initiators'
import Founders from './components/pages/founders'
import Holders from './components/pages/holders'

export default (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/initiators" component={Initiators} />
      <Route exact path="/founders" component={Founders} />
      <Route exact path="/holders" component={Holders} />
      
      {/* <Route component={NotFound} /> */}
    </Switch>
  </BrowserRouter>
)