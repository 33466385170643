import React, { Component } from 'react'
import { Grid, Box, Image } from 'grommet'
import Anchor from '../../atoms/anchor'
import Container from '../../atoms/container'
import logoFile from '../../../img/ndau_orange_logo.png'
import { NAVBAR_COLOR } from '../../../constants'
import './style.css'

const VERTICAL_PAD = "10px"

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.background = props.background || NAVBAR_COLOR;
  }
  
  render() {
    return (
      <Box as="nav" className="Navbar">
        <Box fill background={this.background} elevation="medium">
          <Container topPad="0">
            <Grid
              columns={["flex", "auto", "flex"]}
              rows={["auto"]}
              areas={[
                { name: "left", start: [0, 0], end: [0, 0] },
                { name: "center", start: [1, 0], end: [1, 0] },
                { name: "right", start: [2, 0], end: [2, 0] },
              ]}
              as="header"
            > 
              <Box gridArea="center" align="center" pad={{ vertical: VERTICAL_PAD}}>
                <Anchor href={`/`}>
                  <Box height="40px" width="80px" pad={{vertical: "0"}}>
                    <Image src={logoFile} fit="contain" height="100%"/>
                  </Box>
                </Anchor>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>
    )
  }
}

export default Navbar