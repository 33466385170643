import React, { Component } from 'react'
import { Box } from 'grommet'
import Navbar from '../../organisms/navbar'
import Container from '../../atoms/container'

import style from './style.css'

class Page extends Component {
  render() {
    return(
      <div className={style.dashboard}>
        <Navbar background="#293e63" />
         
        <Container>
          <Box gridArea="main" pad={{vertical: "large" }}>
            {this.props.children}
          </Box>
        </Container>
      </div>
    );
  }
}

export default Page