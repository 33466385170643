import React, { Component } from 'react'
import { 
  Box, Text, Collapsible, Image, Anchor,  
} from 'grommet';
import { Expand, Contract, Share, BlockQuote } from 'grommet-icons'
import Card from '../../atoms/card'

class CandidateCard extends Component {
  constructor(props) {
    super(props)
     
    const { candidate } = props;

    this.name = candidate["Name"];
    this.website = candidate["Website"];
    this.photoURL = candidate["Photo URL"];
    this.status = candidate["Status"];
    this.email = candidate["ndau email address (protonmail)"];
    this.bio = candidate["Why Me"];

    this.state = { 
      showProfile: false
    }
  }
  

  render() {
    const { showProfile } = this.state
    const { cardsBackground, style } = this.props

    const pad = "small"
    return (
      <Card
        background={cardsBackground || "#e1e1e1"}
        onMouseOver={this.makeActive}
        onMouseOut={this.makeInactive}
        style={style}
      >
        <Box
          pad={pad}
          onClick={this.toggleShowProfile}
          background="rgba(255, 255, 255, 0.9)"
        >
          <Box alignSelf="end" width="12px" height="0">
            { 
              (this.bio && showProfile) ? 
              <Contract size="12px" color="#bbb"/> 
              : 
              <Expand size="12px" color="#bbb"/>
              
            }
          </Box>
          
          <Box flex align="center">
            {
              this.photoURL &&
              <Box 
                width={showProfile ? "small" : "xsmall"}
                height={showProfile ? "small" : "xsmall"}
                round="100%" 
                overflow="hidden"
                border={{
                  color: "rgba(255, 255, 255, 0.7)",
                  size: "large",
                  style: "solid",
                  side: "all"
                }}
                style={{display: "inline-flex", transition: "width 0.3s, height 0.3s"}}
                animation="fadeIn"
              >
                <Image 
                  src={this.photoURL} fit="cover" 
                  width="100%"
                  height="100%"
                />
              </Box>
            }
              
            {
              this.website &&
              <Anchor
                href={this.website} 
                icon={<Share size="12px" color="#f99d1c" />}
                reverse
                label={this.name} 
                target="_blank"
                color="black"
                style={{display: "inline-block"}}
              />
            }
          </Box>
        </Box>
        
        {/* About Candidate */}
        {
          this.bio &&
          <Collapsible open={showProfile}>
            <Box 
              pad={{horizontal: "large", vertical: "medium"}} //"large" 
              background="rgba(255, 255, 255, 0.9)"
            >
              <Box>
                <Text>
                  <Text margin={{right: "small"}}>
                    <BlockQuote />
                  </Text>
                  {this.bio}
                </Text>
              </Box>
              
            </Box>
          </Collapsible>
        }
      </Card>
    );
  }

  toggleShowProfile = (event) => {
    event.stopPropagation();
    this.setState(({showProfile}) => { 
      const showProfileState = !showProfile
      return { 
        showProfile: showProfileState,
        // elevation: showProfileState ? "medium" : "none" 
      }
    })
  }
}

export default CandidateCard